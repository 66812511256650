<template>
  <div v-if="showSessionClass">
    <!-- <Heading heading="h4" :content="myTitle"></Heading>
    <hr /> -->
    <div>
      <el-form
        ref="newTest"
        label-width="140px"
        @submit.native.prevent
        @keyup.enter.native="createNew('newTest')"
      >
        <el-form-item label="Educator">
          <el-table border :data="getEducator" class="mb-3">
            <el-table-column label="Name" prop="">
              <template slot-scope="scope">
                {{
                  user.displayName(scope.row.first_name, scope.row.last_name)
                }}
              </template>
            </el-table-column>
            <el-table-column :label="$t('login.email')" prop="email">
            </el-table-column>
            <el-table-column :label="$t('TeacherCourses.Join Date')">
              <template slot-scope="scope">
                {{ instant.formatGoogleTime(scope.row.updated_at) }}
              </template>
            </el-table-column>
            <!-- <el-table-column
              v-if="isAdmin"
              :label="$t('TeacherCourses.Action')"
              width="100px"
            >
              <template slot-scope="scope">
                <el-tooltip
                  effect="dark"
                  content="Remove Student"
                  placement="top"
                >
                  <el-button
                    type="danger"
                    size="small"
                    @click="removeStudent(scope.row)"
                  >
                    <i class="fas fa-user-minus"></i>
                  </el-button>
                </el-tooltip>
              </template>
            </el-table-column> -->
          </el-table>
          <el-tooltip
            effect="dark"
            content="Add Educator"
            placement="top"
            v-if="isAdmin"
          >
            <el-button type="success" size="small" @click="showTeachersWindow">
              <i class="fas fa-user-plus"></i>
              Add Educator
            </el-button>
          </el-tooltip>
        </el-form-item>
        <el-form-item :label="$t('TeacherCourses.Students')" prop="students">
          <el-table border :data="getStudents" class="mb-3">
            <el-table-column label="Name" prop="">
              <template slot-scope="scope">
                {{
                  user.displayName(scope.row.first_name, scope.row.last_name)
                }}
              </template>
            </el-table-column>
            <el-table-column :label="$t('login.email')" prop="email">
            </el-table-column>
            <el-table-column :label="$t('TeacherCourses.Join Date')">
              <template slot-scope="scope">
                {{ instant.formatGoogleTime(scope.row.updated_at) }}
              </template>
            </el-table-column>
            <el-table-column :label="$t('TeacherCourses.Action')" width="100px">
              <template slot-scope="scope">
                <el-tooltip
                  effect="dark"
                  content="Remove Student"
                  placement="top"
                >
                  <el-button
                    type="danger"
                    size="small"
                    @click="removeStudent(scope.row)"
                  >
                    <i class="fas fa-user-minus"></i>
                  </el-button>
                </el-tooltip>
              </template>
            </el-table-column>
          </el-table>
          <el-tooltip
            effect="dark"
            :content="$t('TeacherCourses.Add Student')"
            placement="top"
          >
            <el-button
              v-if="isAdmin"
              type="success"
              size="small"
              @click="showStudentWindow"
            >
              <i class="fas fa-user-plus"></i>
              Add Student
            </el-button>
          </el-tooltip>
          <el-tooltip effect="dark" content="Invite Students" placement="top">
            <el-button
              type="success"
              size="small"
              @click="setLink(course.slug)"
            >
              <i class="fas fa-user-plus"></i>
              Invite Students
            </el-button>
          </el-tooltip>
        </el-form-item>
      </el-form>
    </div>
    <el-dialog
      class="class-link"
      :title="`Class Invitation Link`"
      :visible.sync="showLink"
      width="50%"
    >
      <p>{{ $t("TeacherCourses.Copy and send this link to students") }}</p>
      <div class="text-center">
        <div class="referral-code">
          <a>
            <span>
              {{ link }}
            </span>
          </a>
          <el-button class="ml-3" type="success" @click="copyLink">
            <i class="fas fa-copy"></i>
            Copy Link
          </el-button>
        </div>
      </div>
    </el-dialog>
    <el-dialog
      append-to-body
      :title="$t('TeacherCourses.Add Student')"
      :visible.sync="showStudents"
      width="50%"
    >
      <el-form>
        <el-form-item>
          <el-select
            style="width: 100%;"
            v-model="selectedStudent"
            popper-class="select-popper"
            :popper-append-to-body="false"
            filterable
            remote
            reserve-keyword
            placeholder="Email / Name"
            :remote-method="searchStudents"
          >
            <el-option-group
              v-for="group in students"
              :key="group.from"
              :label="group.from"
            >
              <el-option
                v-for="student in group.users"
                :key="student.id"
                :label="
                  `${user.displayName(
                    student.first_name,
                    student.last_name
                  )} [${student.email}]`
                "
                :value="`${group.from},${student.id}`"
              >
              </el-option>
            </el-option-group>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showStudents = false">Cancel</el-button>
        <el-button type="success" @click="addStudent">Confirm</el-button>
      </span>
    </el-dialog>
    <el-dialog
      append-to-body
      :title="`Add Educator`"
      :visible.sync="showTeachers"
      width="50%"
    >
      <el-form>
        <el-form-item>
          <el-select
            style="width: 100%;"
            v-model="selectedTeacher"
            popper-class="select-popper"
            :popper-append-to-body="false"
            filterable
            remote
            reserve-keyword
            placeholder="Email / Name"
            :remote-method="searchTeachers"
          >
            <el-option-group
              v-for="group in teachers"
              :key="group.from"
              :label="group.from"
            >
              <el-option
                v-for="teacher in group.users"
                :key="teacher.id"
                :label="
                  `${user.displayName(
                    teacher.first_name,
                    teacher.last_name
                  )} [${teacher.email}]`
                "
                :value="`${group.from},${teacher.id}`"
              >
              </el-option>
            </el-option-group>
          </el-select>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showTeachers = false">Cancel</el-button>
        <el-button type="success" @click="addTeacher">Confirm</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
/*eslint-disable no-unused-vars */
import { mapState, mapGetters, mapActions } from "vuex";
/*eslint-enable */
import { user, instant } from "@ivy-way/material";
import Class from "@/views/courses/apis/Add.js";
import Common from "@/mixins/common.js";
import role from "@/mixins/role.js";
import baseDomainPath from "@/common/baseDomainPath";
import Users from "@/views/users/apis/users.js";

export default {
  metaInfo() {
    return {
      title: `${this.myTitle} - ${this.CompanyName}`
    };
  },

  components: {},

  mixins: [Common, role],

  props: [],
  data() {
    return {
      course: null,
      showSessionClass: false,
      showLink: false,
      showStudents: false,
      selectedStudent: null,
      students: [
        {
          from: this.CompanyName,
          users: null
        },
        {
          from: "Ivyway",
          users: null
        }
      ],
      showTeachers: false,
      selectedTeacher: null,
      teachers: [
        {
          from: this.CompanyName,
          users: null
        },
        {
          from: "Ivyway",
          users: null
        }
      ]
    };
  },
  computed: {
    ...mapState("user", ["profile", "token"]),
    user() {
      return user;
    },
    instant() {
      return instant;
    },
    isAdmin() {
      return this.isRoleAdmin();
    },
    isStudent() {
      return false;
    },
    myTitle() {
      return "Edit Roles";
    },
    getStudents() {
      if (this.course.users["member"]) {
        return this.course.users["member"];
      } else {
        return [];
      }
    },
    getEducator() {
      if (this.course.users["owner"]) {
        return this.course.users["owner"];
      } else {
        return [];
      }
    }
  },
  watch: {},

  async mounted() {
    if (this.$route.params.id) {
      this.showSessionClass = false;
      await this.getClass(this.$route.params.id);
    }
    if (this.isTeacher && !this.isEdit) {
      if (this.teacher_users.length === 0) {
        this.teacher_users.push(this.getProfile);
      }
    }
    this.showSessionClass = true;
  },

  methods: {
    async addTeacher() {
      if (this.selectedTeacher) {
        let isIn = false;
        const [source, uid] = this.selectedTeacher.split(",");
        this.getEducator.forEach(user => {
          if (uid === user.id) {
            isIn = true;
          }
        });
        if (isIn) {
          this.$message({
            message: "Cannot be added repeatedly!",
            type: "warning"
          });
        } else {
          let data = {
            users: [],
            ivy_way_users: []
          };
          if (source === this.CompanyName) {
            data.users.push({
              id: uid,
              role: "owner"
            });
          }
          if (source === "Ivyway") {
            data.ivy_way_users.push({
              id: uid,
              role: "owner"
            });
          }
          const res = await Class.addUsers(this.$route.params.id, data);
          this.showTeachers = false;
          await this.getClass(this.$route.params.id);
        }
      }
    },
    async addStudent() {
      if (this.selectedStudent) {
        let isIn = false;
        const [source, uid] = this.selectedStudent.split(",");

        this.getStudents.forEach(user => {
          if (uid === user.id) {
            isIn = true;
          }
        });
        if (isIn) {
          this.$message({
            message: "Cannot be added repeatedly!",
            type: "warning"
          });
        } else {
          let data = {
            users: [],
            ivy_way_users: []
          };
          if (source === this.CompanyName) {
            data.users.push({
              id: uid,
              role: "member"
            });
          }
          if (source === "Ivyway") {
            data.ivy_way_users.push({
              id: uid,
              role: "member"
            });
          }
          const res = await Class.addUsers(this.$route.params.id, data);
          this.showStudents = false;
          await this.getClass(this.$route.params.id);
        }
      }
    },
    showTeachersWindow() {
      this.showTeachers = true;
      this.selectedTeacher = null;
    },
    showStudentWindow() {
      this.showStudents = true;
      this.selectedStudent = null;
    },
    async searchTeachers(query) {
      const res = await Users.getUsers({
        search: query !== "" ? query : null,
        per_page: 50,
        role: "teacher"
      });
      this.users = res.data;
      this.teachers[0].users = res.data;

      const ivywayRes = await Class.getIvyWayUsers({
        search: query !== "" ? query : null,
        per_page: 50,
        role: "teacher"
      });
      this.teachers[1].users = ivywayRes.ivy_way_users.data;
    },
    async searchStudents(query) {
      const res = await Users.getUsers({
        search: query !== "" ? query : null,
        per_page: 50,
        role: "student"
      });
      this.users = res.data;
      this.students[0].users = res.data;

      const ivywayRes = await Class.getIvyWayUsers({
        search: query !== "" ? query : null,
        per_page: 50,
        role: "student"
      });
      this.students[1].users = ivywayRes.ivy_way_users.data;
    },
    removeStudent(user) {
      this.$confirm(
        "Are you sure you want to remove the user from the class?",
        "Confirmation",
        {
          confirmButtonText: "Confirm",
          cancelButtonText: "Cancel",
          type: "warning"
        }
      ).then(async () => {
        await Class.removeStudent(this.$route.params.id, {
          user_id: user.id
        });
        await this.getClass(this.$route.params.id);
      });
    },
    
    async getClass(id) {
      const res = await Class.getClassDetail(id);
      this.course = res;
    },
    setLink(code) {
      this.linkType = "Class";
      this.link = `${baseDomainPath}student/classes/join?code=${code}`;
      this.showLink = true;
    },
    copyLink() {
      var oInput = document.createElement("input");
      oInput.value = this.link;
      document.body.appendChild(oInput);
      oInput.select();
      document.execCommand("Copy");
      oInput.className = "oInput";
      oInput.style.display = "none";
      this.showLink = false;
      this.$message({
        message: "Copied",
        type: "success"
      });
    }
  }
};
</script>

<style scoped>
::v-deep .select-popper {
  z-index: 9999 !important;
  top: auto !important;
  left: auto !important;
}
.box {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  padding-top: 60px;
}
.box .left-menu {
  width: 200px;
  height: 100%;
}
.box .right-content {
  background: #fafafa;
}
.right-content-detail {
  width: inherit;
  margin-left: 72px;
  margin-right: 72px;
  margin-top: 20px !important;
  padding-bottom: 20px;
}
.button-group {
  padding: 1rem 20px;
}
::v-deep .el-menu {
  border: none;
}
::v-deep .button-group .el-button {
  width: 100%;
  margin: 0.5rem 0;
}
.right-content {
  flex: 1;
  height: 100%;
  overflow: auto;
}

.referral-code span {
  display: inline-block;
  height: 41px;
  line-height: 41px;
  padding: 0 1rem;
  font-size: 1rem;
  background: rgb(241, 243, 244);
  border-radius: 4px;
}
::v-deep .class-link .el-dialog__body {
  padding-top: 0.2rem;
}
</style>
